
.App {
  text-align: center;
  
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.User-bar-style {
  padding-bottom: 2px;
  border-bottom: 1px solid #068BC9;
}

/*overwrite default styles */
/* .card__content {
  padding: 0.5rem;
} */

/*saved card item */
.saved-card .form-control.form-control--radio{
  margin-bottom: 0px;
}
 .saved-card{
  margin-bottom: 10px;
} 

/*external login button*/
.external-login-button-wrap button{
  width: 165px !important;
  border: 1px solid lightgray !important;
  font-size: 14px !important;
  border-radius: 4px !important;  
  text-transform: capitalize;
  font-weight: normal;
  box-shadow: none !important;
  padding: 5px 2px 2px 5px !important;
  height: 55px !important;
  text-align: center;
}
.external-login-button-wrap{
  margin-bottom: 10px;
  text-align: center;
}

  .kep-login-facebook .fa {
    margin-right: 14px !important
  }

  /*form */
  .input[readonly]{
    background-color: #e9e9e9;
    color: #5b5b5b;
  }
  .input{
    background-color: #f3f3f3;
    border: 1px solid #d9d9d9;
    color: #5b5b5b;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d9d9d9;
    opacity: 1; /* Firefox */
  }




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
